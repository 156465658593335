
  import { defineComponent } from "vue";

  import { isNull, omitBy } from "lodash";

  import baseProcessComponent from "@/components/processComponents/BaseProcessComponent.vue";

  export default defineComponent({
    name: "FormTemplates",

    extends: baseProcessComponent,

    computed: {
      value: {
        get() {
          return this.$attrs.modelValue || {};
        },

        set(value: any) {
          this.$emit("update:modelValue", this.sanitizeOutput(value));
        },
      },
    },

    methods: {
      /*  TODO: Idealmente la limpieza de valores devueltos por los componentes debería ser parametrizada en su
       *  configuración, es decir, si el elemento es requerido su llave debe existir siempre en el valor de salida.
       *  Para el valor del elemento de debe definir si puede ser 'nullable'; en caso de ser falso indicará
       *  que la llave del elemento será omitida de la salida si su valor se considera falsy.
       */
      sanitizeOutput(value: any): any {
        return omitBy(value, (v) => v === undefined || isNull(v) || v === "");
      },
    },
  });
